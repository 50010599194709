/* src/App.css */
:root {
  background-color: #0A0A0A
}
.App {
  text-align: center;
  padding: 20px;
  background-color: #0A0A0A
}

